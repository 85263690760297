import { Outlet } from "react-router-dom";

import Navigation from '../components/Navigation';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

export default function Root() {
  return (
    <>
      <Navigation />
      <Banner />
      <div className="container mx-auto">
        <Outlet />
      </div>   
      <Footer />
    </>
  );
}
