// <a href="" target="_blank" rel="noopener noreferrer"></a>
// <img src=${process.env.PUBLIC_URL + "/img/news/"} />
// <p></p>

const posts = [
  {
    
    id: "kT4m2YxQ",
    title: "Unlocking the Potential of ELTIF 2.0: A Catalyst for African Development and EU Global Gateway Success",
    tags: [],
    date: new Date(Date.UTC(2024, 7, 13)),
    content:
      `
        <p class="flex justify-center my-5"><img src=${process.env.PUBLIC_URL + "/img/news/kT4m2YxQ/035e0ad4-94a2-4587-a68b-f2bb4f625947.jpg"} /></p>
        <p>As representatives of the Brussels-Africa Hub, we recognize the immense potential of the European Long-Term Investment Fund (ELTIF) 2.0 to revolutionize the mobilization of capital for impactful and productive projects across Africa. This vehicle represents a crucial link between European capital markets and Africa’s development, leveraging the power of both European and African diaspora capital to create sustainable growth and development on the continent. Our recent discussions with representatives of the European institutions have only reinforced our belief in the transformative power of ELTIF 2.0, particularly in the context of the EU Global Gateway strategy.</p>
        <p>The EU Global Gateway aims to foster resilient and sustainable links between Europe and the rest of the world, with a strong focus on Africa. However, for this ambitious strategy to succeed, it requires not just public funding but also significant private sector investment. This is where ELTIF 2.0 comes into play. By facilitating long-term investments in infrastructure, social housing, renewable energy, and other critical sectors, ELTIF 2.0 can attract both retail and institutional investors, creating a robust financial ecosystem that supports Africa's development goals.</p>
        <p>One of the most compelling aspects of ELTIF 2.0 is its ability to bring retail investors into the fold. Traditionally, retail investors have had limited access to long-term investment opportunities in emerging markets. ELTIF 2.0 changes this dynamic by providing a regulated, transparent, and accessible investment vehicle that allows retail investors to participate alongside seasoned institutional investors. This democratization of investment opportunities not only mobilizes a broader base of capital but also fosters a deeper connection between European citizens and Africa's development.</p>
        <p>Moreover, ELTIF 2.0 is designed to align with the EU's stringent regulatory standards, ensuring that investments are made responsibly and sustainably. This alignment is crucial for building trust among investors and ensuring that the capital deployed has a real and lasting impact. By prioritizing projects that are both productive and impactful, ELTIF 2.0 can help bridge the infrastructure gap in Africa, support the growth of SMEs, and promote inclusive economic development.</p>
        <p>Our meeting with representatives of the European institutions highlighted several key measures that have been implemented to support European SMEs' access to long-term funding via the EU capital markets. These measures are equally relevant to African SMEs, which often face similar challenges in accessing the capital they need to grow and thrive. By extending these initiatives to African markets, we can create a more integrated and supportive financial ecosystem that benefits both continents.</p>
        <p>Furthermore, the European diaspora community, which has deep ties to Africa, represents a significant and often underutilized source of capital. ELTIF 2.0, which benefits from a EU marketing passport,  can serve as a bridge, enabling diaspora investors to channel their resources into projects that drive economic and social progress in their countries of origin. This not only supports development in Africa but also strengthens the connections between Europe and Africa, fostering a sense of shared purpose and mutual benefit.</p>
        <p>In conclusion, ELTIF 2.0 has the potential to be a game-changer for the EU Global Gateway strategy, providing a vital mechanism for mobilizing both public and private capital towards impactful investments in Africa. By leveraging this innovative investment vehicle, we can create a more inclusive, sustainable, and prosperous future for both Europe and Africa. The Brussels-Africa Hub is committed to working with partners  across the financial sector to realize this vision and unlock the full potential of ELTIF 2.0.<br/>Together, we can transform the investment landscape, drive meaningful development, and ensure that the EU Global Gateway delivers on its promise of resilient and sustainable growth for Africa and Europe alike.</p>
        <p>Authors: <a href="https://www.linkedin.com/in/mouctar-bah-48a8a425/" target="_blank" rel="noopener noreferrer">Mouctar BAH</a>, President of Brussels-Africa Hub and <a href="https://www.linkedin.com/in/guillaume-grange-8153a891/" target="_blank" rel="noopener noreferrer">Guillaume Grange</a>, member of the Advisory Board of Brussels-Africa Hub</p>
      `
  },
  {
    
    id: "uPnwFP5O",
    title: "Infrastructure development finance: A discussion with the African diplomatic corps in Brussels",
    tags: [],
    date: new Date(Date.UTC(2024, 5, 12)),
    content:
      `
        <p>On 7th June, the Brussels-Africa Hub hosted its second Diplomatic Roundtable Discussion in close collaboration with the Embassy of the Republic of Kenya in Brussels.</p>
        <p>This exclusive event aimed to facilitate dialogue among African diplomats, EU and Team Europe institutions, and private sector organizations on the implementation of the Global Gateway Africa-Europe Investment Package.</p>
        <p>H.E. Bitange Ndemo, the Ambassador of Kenya to Belgium and the EU, delivered inspiring opening remarks, emphasizing the need to prioritize African interests in the Global Gateway's implementation. Following his speech, Ms Geneviève-Anne Dehoux (European Commission) and Mr Yohann Chaigneau (European Investment Bank) provided insights into the current state of European investment into strategic infrastructure across the African continent. The ensuing discussions focused on "Boosting Africa-Europe value chains" and were expertly led by Holy Ranaivozanany, Deputy Executive Director of the Africa-Europe Foundation.</p>
        <p>As a member of the Global Gateway Dialogue Platform, the Brussels-Africa Hub remains committed to mobilizing the European and African financial ecosystems for a sustainable and effective of the Africa-EU Partnership.</p>
        <p>If you wish to be added to our distribution list, feel free to subscribe <a href="http://eepurl.com/c-4RcT" target="_blank" rel="noopener noreferrer">here</a>.</p>
        <div class="flex items-center justify-center flex-wrap mt-6">
          <img src=${process.env.PUBLIC_URL + "/img/events/vdxmkzHy/photos/95d57143-cef4-4d9b-a2f1-c9c2e0a94156.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/vdxmkzHy/photos/133a04cb-a83d-41a6-a121-ccbddece974e.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/vdxmkzHy/photos/9c7e990a-fbe6-4776-83f9-04a5b3e5a1a2.jpeg"} class="max-h-64 m-1.5"/>
        </div>
        <p class="mt-6">The photos can be found on the <a href="/events/vdxmkzHy/diplomatic-breakfast-roundtable-boosting-africa-europe-value-chains-is-the-global-gateway-fit-for-purpose">event page</a>.</p>
      `
  },
  {
    
    id: "QHzSU60D",
    title: "Event Report: Impactful Investments & Opportunities - Europe & Africa",
    tags: [],
    date: new Date(Date.UTC(2024, 3, 12)),
    content:
      `
        <p>The event on "Impactful Investments & Opportunities - Europe & Africa" took place on March 14th, 2024 at Arendt House. It was co-organized by Brussels-Africa Hub, Arendt, and LuxAfrica, marking a significant discussion on the economic relationship between Europe and Africa.</p>
        <h3>Keynote Speech</h3>
        <p>The event kicked off with a keynote speech by Mr. Loïc De Cannière, the founder and Chairman of Incofin Investment Management. His presentation, titled "Major trends and overview of geopolitics and geo-economics of Africa," offered a balanced perspective on the continent's economic landscape. Mr. De Cannière highlighted both opportunities and challenges in Africa, emphasizing the ripe moment for European investors to engage with African markets. He supported his arguments with concrete data, providing a comprehensive overview of the potential for economic cooperation between the two continents.</p>
        <h3>Panel Discussions</h3>
        <h4>Panel 1: EU, Luxembourg, and Belgium Initiatives</h4>
        <p>The first panel, brilliantly moderated by Mr. Guillaume Grange of Brussels-Africa Hub, focused on “EU, Luxembourg and Belgium initiatives for mobilizing impactful investments in Africa.” The panel featured notable speakers:</p>
        <ul>
          <li>Ms. Charlotte Julie Rault from the European Investment Bank</li>
          <li>Mr. Paul Weber from the Ministry of Foreign and European Affairs of Luxembourg</li>
          <li>Mr. Jean Van Wetter, CEO of the Belgian Development Agency</li>
        </ul>
        <p>This session shed light on the concerted efforts and strategies deployed by European entities to foster sustainable and impactful investments in Africa, showcasing a commitment to deepen economic ties with African nations.</p>
        <h4>Panel 2: Success Stories with a Focus on Africa</h4>
        <p>The second panel, also brilliantly moderated by Mr. Bertrand Moupfouma of LuxAfrica, centered on "Success Stories with a Focus on Africa." It featured:</p>
        <ul>
          <li>Ms. Doris Köhn, the chairperson of the Africa Agriculture Investment Fund and former Director General of KfW</li>
          <li>Mr. Loïc De Cannière representing Incofin Investment Management</li>
          <li>Mr. Alex Panican from the Luxembourg House of Financial Technology (LHoFT)</li>
        </ul>
        <p>This panel highlighted various successful investment stories in Africa, underscoring the potential for profitability and impact in the African market. It offered practical insights and inspiration for potential investors, illustrating the diverse opportunities across sectors.</p>
        <h3>Closing Remarks</h3>
        <p>Ms. Stéphanie Lhomme, a partner at Arendt, provided the closing remarks for the event. She addressed the common misperceptions among European investors about risks associated with investing in Africa. Ms. Lhomme's speech was a call to action for European investors and financing institutions, urging them to recognize the current moment as an optimal time to invest in Africa and to change the prism with which they evaluate, considering the continent's vast opportunities and the potential for significant returns on investment.</p>
        <p><i>“Africa will be a key engine for global growth over the next 30 years. With resource-rich countries, rapid economic growth, increasing political stability (with a few exceptions there) and an entrepreneurial environment, international companies see clear investment opportunities across the continent.”</i></p>
        <h3>Conclusion</h3>
        <p>The "Impactful Investments & Opportunities - Europe & Africa" event served as a pivotal platform for discussing the economic synergies between Europe and Africa. It brought together key stakeholders and experts to explore the ways in which impactful investments can drive sustainable growth and development in Africa, while also offering profitable opportunities for European investors. Through its informative keynote speech, insightful panels, and inspiring closing remarks, the event successfully highlighted the need for increased economic cooperation between the two continents and laid down a path for future engagements.</p>
        <div class="flex items-center justify-center flex-wrap mt-6">
          <img src=${process.env.PUBLIC_URL + "/img/events/o27jXq5i/photos/2fe1090e-f83b-4225-85e4-a785e9857670.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/o27jXq5i/photos/4efcda1b-9f82-4390-9f88-8dae9aebd8ee.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/o27jXq5i/photos/6ae3bde0-8b9b-4285-8acb-b31e0ed00144.jpeg"} class="max-h-64 m-1.5"/>
        </div>
      `
  },
  {
    
    id: "7qsxwB80",
    title: "Insights from our first Diplomatic Breakfast Roundtable in Brussels",
    tags: [],
    date: new Date(Date.UTC(2024, 2, 23)),
    content:
      `
        <p>
          On March 8<sup>th</sup>, the Brussels-Africa Hub held its inaugural Diplomatic Breakfast Roundtable, a quarterly event aimed at fostering collaboration and dialogue with African ambassadors in Brussels. The focus of this gathering was the Global Gateway Africa-Europe Investment Package which aims to boost smart, clean and secure links in digital, energy and transport sectors and to strengthen health, education and research systems in Africa.
        </p>
        <p>
          WTS Global, a member of the Brussels-Africa Hub, hosted the roundtable, which brought together ambassadors from various countries in the Southern African Development Community (SADC). This regional approach provided a valuable opportunity to engage with diplomats, representatives from EU institutions, the Belgian development agency, and the private sector. The discussions centered around the multifaceted implementation of the Global Gateway initiative, whereby the EU plans to invest around €150B in infrastructure in Africa by 2027, and its potential implications for the EU-SADC Economic Partnership Agreement.
        </p>
        <p>
          In her opening remarks, Her Excellency Mme Tokozile Xasa, the South Africa Ambassador, emphasized the significance of understanding the goals of the Global Gateway from an African perspective. She also stressed the importance of unity and strategy.
        </p>
        <p>
          Mouctar Bah, the President of the Brussels-Africa Hub, highlighted the role and responsibility of diasporas as facilitators and bridge-builders, starting from Brussels as a unique hub of knowledge on EU-Africa relations.
        </p>
        <p>
          The discussion focused on the mechanisms governing the Global Gateway, the need for increased engagement with institutions, and the requirement for a fresh approach to EU-Africa collaboration. Mr. Loïc De Cannière, a board member and head of Incofin Investment Management, facilitated the conversation.
        </p>
        <p>
          Furthermore, the gathering provided an opportunity to gain insights from the Africa-Europe Foundation and their presentation of the "AU-EU Tracker" report. This report offers up-to-date information, data, and expert analysis on the partnership between Africa and Europe.
        </p>
        <p>
          The Brussels-Africa Hub, leveraging its membership in the Global Gateway Dialogue Platform, plans to organize these quarterly informal gatherings to foster the dialogue between the decision-makers  and business community of both continents.
        </p>
        <div class="flex items-center justify-center flex-wrap mt-6">
          <img src=${process.env.PUBLIC_URL + "/img/events/TkQHKyWh/photos/4cbf189f-b1be-4bc4-8a9c-465d1f9ca111.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/TkQHKyWh/photos/5b606d21-6feb-4236-b10c-5fc8371e4942.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/TkQHKyWh/photos/6811a62b-9bd3-4f8e-8d45-9f82d1339de0.jpeg"} class="max-h-64 m-1.5"/>
        </div>
      `
  },
  {
    
    id: "y61kUraY",
    title: "Gallium: The Golden Ticket for Guinea and Europe's Prosperity and Global Technological Advancement",
    tags: [],
    date: new Date(Date.UTC(2024, 1, 25)),
    content:
      `
      <p class="flex justify-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/3afd588a-2ed0-4b8d-8db5-982310622e21.jpeg"} />
      </p>
      <h3>
        Executive summary 
      </h3>
      <ul>
          <li>Guinea holds 1/3 of known global Bauxite reserve in the world</li>
          <li>Guinea currently produces approximately 86 million metric ton of Bauxite per annum</li>
          <li>80% of Guinea's Bauxite production is shipped to China</li>
          <li>China's import of Guinean Bauxite represents approximately 60% of its global import</li>
          <li>China produces 60% of the global Aluminium and 80% of the global Gallium</li>
          <li>One ton of Bauxite harbors not only Aluminium but also Gallium</li>
          <li>Europe currently import 71% of its Gallium from China</li>
          <li>Guinea could supply the European market both in Aluminium and Gallium</li>
      </ul>
      <h3>
        Introduction
      </h3>
      <p>
        On July 14, 2023 the <a href="https://www.ft.com/content/b08c11fe-8b09-4819-b8be-02e63b0bf2df" target="_blank" rel="noopener noreferrer">Financial Times</a> wrote an article with the following title:
      </p>
      <p class="flex justify-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/3d801bc4-17d0-44be-b421-1230dcd3491f.png"} class="max-w-3/4" />
      </p>
      <p>
        In that article of the Financial Times the following paragraph was included: 
        <i>“The EU has approached <a href="https://www.mytilineos.com/what-we-do/metallurgy-sector/" target="_blank" rel="noopener noreferrer">Mytilineos Energy & Metals</a>, a Greek aluminium producer, and asked it to explore producing gallium as a byproduct at its refinery that turns bauxite into alumina, a starting material for aluminium, in Agios Nikolaos in mainland Greece.”</i>
      </p>
      <h3>
        General
      </h3>
      <p>
        In the intricate world of minerals, one element is quietly paving the way for economic prosperity and technological innovation: Gallium. Recent research, particularly the paper <i>”Research progress on the enrichment of gallium in bauxite“ of Qi et al., 2023</i> published in the scientific review <i>“Ore Geology Reviews“</i>, sheds light on the transformative role of Gallium in diverse industries such as semiconductors, medicine, renewable energy, and defense. Moreover, Guinea, possessing a staggering one-third of the global deposit of Bauxite, has the potential to emerge as an economic powerhouse by strategically harnessing the wealth hidden within its soil. Figure 1 below of Qi et al., 2023 shows the average content of Gallium of the Guinean bauxite in parts per million (ppm).
      </p>
      <p class="flex flex-col items-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/c952a21c-fdfb-4399-851a-81e43085ff9c.png"} class="max-w-3/4" />
        <i class="mt-1 text-sm max-w-3/4 text-center">Figure 1: Average content of Gallium in Bauxite of different countries (Qi et al., 2023)</i>
      </p>
      <p>
        The pivotal role of Gallium in modern technology cannot be overstated. Gallium's unique properties make it an essential component in semiconductor manufacturing, where it enhances the performance and efficiency of electronic devices. The recent research progress highlighted in <i>Ore Geology Reviews</i> underscores the importance of Gallium enrichment in Bauxite, offering a pathway for countries like Guinea to tap into this valuable resource and propel themselves into the forefront of the global technology market.
      </p>
      <p>
        Guinea's wealth extends beyond traditional Aluminium extraction, as each ton of Bauxite harbors not only Aluminium but also Gallium. This dual extraction capability positions Guinea as one of the wealthiest countries globally, holding the key to two critical elements that drive technological progress. Guinea's Bauxite reserves become, in essence, a treasure trove capable of fueling economic development on an unprecedented scale. 
      </p>
      <p>
        The global landscape, however, introduces challenges and opportunities. According to Bank of America Merrill Lynch and Financial Times, China controls a significant portion of both Aluminium and Gallium production, with approximately 60% of global Aluminium production and a staggering 80% of global Gallium production under its dominion. This control extends to the import of Bauxite, with China relying on Guinea for almost 60%, according to Bloomberg report, of its global Bauxite needs to produce Aluminum and Gallium. In current figures this represents 80% of the total Bauxite production of Guinea, i.e., around 86 million metric ton per annum (which, at current price, is approximately equal to USD 6 billion). The actual Bauxite production of Guinea is equivalent to approximately 21.5 million metric ton of Aluminium<sup>1</sup>, which at actual price is approximately USD 50 billion.
      </p>
      <p>
        According to the below Figure 2 of Bank of America Merrill lynch / Woodmac, Guinea could by 2030 produce 180 million metric ton of Bauxite per annum. This is equivalent to 45 million metric ton of Aluminium per annum, which, at current price, is approximately equivalent to USD 100.8 billion. Next, at around 60 ppm Gallium tenor, Qi et al., 2023, Guinea has the potential to produce over 5,400 metric ton of Gallium per annum by 2030<sup>2</sup>.
      </p>
      <p class="flex flex-col items-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/b80a4b0f-d8cc-4990-aca9-5ec20c4b4ed0.png"} class="max-w-3/4" />
        <i class="mt-1 text-sm max-w-3/4 text-center">Figure 2: New Bauxite Projects in Guinea (Bank of America Merrill Lynch / Woodmac)</i>
      </p>
      <p>
        In a parallel narrative, Europe, heavily dependent on China for 71% of its Gallium imports, finds itself in a vulnerable position concerning the security and diversity of its supply chain. This dependency underscores the urgent need for collaboration and diversification. Herein lies a unique opportunity for Guinea and Europe to forge a strategic alliance based on their shared interests and complementary needs. The below Figure 3 and Figure 4 of Bank of America Merrill Lynch gives an overview of the required CAPEX to build a greenfield <span class="font-semibold">Alumina</span> and <span class="font-semibold">Aluminium</span> smelter in different regions in the world. Bank of America Merrill Lynch / Woodmac estimate that the current average cost to build a greenfield Alumina and Aluminium smelter is around $1000 for Alumina and $2000 for Aluminium per ton. In the context of the EU Green deal and Global Gateway a Public Private Partnership should make it competitive to produce Aluminium and Gallium in Guinea or nearby Senegal (<i>enough gas is immediately available to start production</i>). The Inflation Reduction Act and Chips Act of the United State of America could be a good reference here.
      </p>
      <p class="flex flex-col items-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/05e5ec2a-6064-4941-a0ee-a5144b9a00a8.png"} class="max-w-3/4" />
        <i class="mt-1 text-sm max-w-3/4 text-center">Figure 3: CAEPX intensity for greenfield (green bars) Alumina smelters in 2023 $ per ton (Bank of America Merrill Lynch / Woodmac) - blue bars are brownfield projects</i>
      </p>
      <p class="flex flex-col items-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/47fafd92-204e-4606-8ba7-bf7dac71f882.png"} class="max-w-3/4" />
        <i class="mt-1 text-sm max-w-3/4 text-center">Figure 4: CAEPX intensity for greenfield Aluminium smelters in 2023 $ per ton (Bank of America Merrill Lynch / Woodmac) - red bars are China based projects</i>
      </p>
      <p>
        According to Bank of America Merrill Lynch research, the breakdown of the production cost of Alumina and Aluminium, cfr. Figure 5, is mainly focused around Bauxite and energy. The energy need varies between 14-15 MWh (Megawatt hour) per ton Aluminium. With the resent discovery and production of gas in Senegal, a large regional vertically integrated Energy > Bauxite > Alumina > Gallium > Aluminium mineral company could be built at the border between Guinea and Senegal.  
      </p>
      <p class="flex flex-col items-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/984d5bfb-dde2-4450-8dc2-0a421e64c5c2.png"} class="max-w-3/4" />
        <i class="mt-1 text-sm max-w-3/4 text-center">Figure 5: Breakdown of production cost - left figure is Alumina and right figure is Aluminium (Bank of America Merrill Lynch / Woodmac)</i>
      </p>
      <p>
        Europe and Guinea are, by virtue of these statistics, natural partners in the quest to secure a stable and diversified supply chain for Aluminium and Gallium. By collaborating to shorten the supply chain, these entities can mitigate geopolitical risks, enhance supply chain security, and stimulate economic growth. Joint ventures, technology transfer programs, and infrastructure development initiatives could be explored to solidify this partnership.
      </p>
      <p>
        Guinea, with its abundant Bauxite reserves, has the potential to not only meet China's demand but also to establish itself as a reliable partner for Europe. The collaboration could be a catalyst for Guinea's economic development, creating jobs, fostering technological innovation, and diversifying the nation's revenue streams.
      </p>
      <p>
        According to Bloomberg figures, in 2015 only 0,6% of Guinea's Bauxite was exported to China, by end of 2020 80% of the total Bauxite production in Guinea was exported to China. Bloomberg estimates that almost 2/3 of China's global import of Bauxite comes from Guinea. This shift of Chinese companies towards Guinea is a direct consequence of the Indonesian and Malaysian industrialization policies. Indeed, in 2014 the Indonesian Government took the decision to ban the export of raw Bauxite, two years later Malaysia followed suit<sup>3</sup>. Fast-forward, in 2024 (10 years later) Indonesia has started commercializing its made in Indonesia Electrical Vehicles (EVs) of which the locally processed Aluminium, Nickel and Cobalt are key components. According to <a href="https://www.reuters.com/business/autos-transportation/indonesia-relaxes-tax-rules-ev-imports-attract-investment-2023-12-13/" target="_blank" rel="noopener noreferrer">Reuters</a> Indonesia targets to produce 600,000 EVs by 2030.
      </p>
      <h3>
        Zoom in on Aluminium
      </h3>
      <p>
        According to Bank of America figures, in 2020 the European Union (EU-27) imported 840,000 ton of Aluminium from Russia, of which <a href="https://rusal.ru/en/" target="_blank" rel="noopener noreferrer">Russal</a> is the global leader, with large Bauxite mining operations in Guinea. But since the start of the war in Ukraine the EU-27 has been confronted with a trilemma: (i) stop importing Aluminium from Russia?; (ii) due to the war induced energy cost, rescue EU based Aluminium smelters from closure?; (iii) negate the EU Green Deal and import from countries like India of which the Aluminium smelters are powered by coal fired powerplants?
      </p>
      <p>
        Indeed, according to Bank of America one ton Aluminium produced in India contains <b>16 ton of CO<sub>2</sub> emission</b>, while the same ton is produced in Russia with 4 ton of CO<sub>2</sub> emission. I think if CO<sub>2</sub> emission related to the transport of unprocessed Bauxite is factored in, Guinea/ Senegal production model could achieve similar CO<sub>2</sub> emission efficiency as Russia. Figure 6 gives an overview of the top 3 countries where the EU-27 is sourcing currently its Aluminium, while Figure 7 gives an indication of the potential global shortage of Aluminium under different demand scenarios (Bank of America sees a 4% demand growth per annum as the base scenario, which corresponds to <b>15,000,000 ton of shortage by 2030</b>).
      </p>
      <p class="flex flex-col items-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/c165f779-1cdd-46f6-9827-2729f305bfd8.png"} class="max-w-3/4" />
        <i class="mt-1 text-sm max-w-3/4 text-center">Figure 6: European Union 27 member countries, Aluminium import from top-3 trading partners in 1000 tons (Bank of America Merrill Lynch / Woodmac)</i>
      </p>
      <p class="flex flex-col items-center my-5">
        <img src=${process.env.PUBLIC_URL + "/img/news/y61kUraY/232a935a-6d6d-4210-82fa-5008574eb922.png"} class="max-w-3/4" />
        <i class="mt-1 text-sm max-w-3/4 text-center">Figure 7: Potential global Aluminium shortage in 1000 tons  under different demand scenarios by 2030 (Bank of America Merrill Lynch / Woodmac)</i>
      </p>
      <h3>
        Potential European private sector partner
      </h3>
      <p>
        In my opinion the likes of <i><a href="https://www.mytilineos.com/what-we-do/metallurgy-sector/" target="_blank" rel="noopener noreferrer">Mytilineos Energy & Metals</a></i> with a vertical integration could be the right partner to build and operate this new Europe-Africa <i>vertically integrated</i> mineral company with HoldCo in Brussels:
      </p>
      <p>
        <span class="font-semibold">Energy > Bauxite > Alumina > Gallium > Aluminium</span>. Indeed, with less than 10% of the €150bn of the EU Global Gateway funds, a state-of-the-art facility could be built in line with the EU Green deal requirements. This model would spur the industrialization of Africa while guaranteeing the autonomy of Europe and Africa and provide directly and indirectly millions of jobs.
      </p>
      <h3>
        Conclusion
      </h3>
      <p>
        In conclusion, Gallium's significance in semiconductors, medicine, and defense applications, coupled with Guinea's vast Bauxite deposits, positions the country at the nexus of global technological advancement. The collaboration between Guinea and Europe, as natural partners, presents a strategic opportunity to reshape the global supply chain, fostering economic strength and resilience. As these nations embark on this journey, they hold not just the key to economic prosperity but also the promise of steering global industries towards a more sustainable and interconnected future.
      </p>
      <p>
        <span class="font-semibold">About the author:</span> Mouctar Bah is the President of <a href="https://brusselsafricahub.eu/" target="_blank" rel="noopener noreferrer">Brussels-Africa Hub</a>, an international non-profit organization based in Brussels.
      </p>
      <hr class="my-6"/>
      <p class="text-sm">
        <sup>1</sup> 2 ton of Bauxite is needed to produce 1 ton of Alumina and 2 ton of Alumina is needed to produce 1 ton Aluminium. Therefore, 86M ton of Bauxite / 4 = 21.5M ton of Aluminium<br/>
        <sup>2</sup> 0.00006 (Gallium tenor) x 180M / 2 (90M ton of Bauxite residual generated in the Alumina production process) = 5.400 ton of Gallium<br/>
        <sup>3</sup> Source: <a href="https://www.ft.com/content/d98c10e4-c92a-11e6-8f29-9445cac8966f" target="_blank" rel="noopener noreferrer">Financial Times</a>
      </p>
      `
  },
  {
    
    id: "Sx7I7AXo",
    title: "Our new year reception celebrated the start of Belgium's EU Presidency",
    tags: [],
    date: new Date(Date.UTC(2024, 0, 19)),
    content:
      `
      <p class="font-semibold">
        On January 18th, the Brussels-Africa Hub hosted a high-level networking reception to mark the first days of Belgium's EU Presidency. 
      </p>
      <p>
        In his keynote address, Mr. Thomas Stevens, Deputy Director for Sub-Saharan Africa at the Ministry of Foreign Affairs Belgium, emphasized the crucial role that Brussels is meant to play in promoting sustainable investment in Africa, while Mrs. Soraya Hakuziyaremye, Vice-Governor of the National Bank of Rwanda, highlighted the measures being implemented to attract global financial players and investors to Rwanda.
      </p>
      <p>
        The event was organized in collaboration with the Belgian development agency (Enabel) and started with an opening speech by Mr. Sven Huyssen, Director of Operations at Enabel. 
      </p>
      <p>
        It brought together more than 100 high-ranking representatives from EU and Belgian institutions, banks, business advisory firms, and academia. Several members of the African diplomatic corps were also present at the event.
      </p>
      <p>
        As Belgium assumes the Belgian Presidency of the Council of the European Union 2024, it is presented with a significant opportunity to guide the EU towards a stronger partnership with Africa and a more ambitious involvement of the private sector, all amidst a complex geopolitical landscape.
      </p>
      <p>
        The photos can be found on the <a href="/events/JCrkE081/2024-new-year-reception">event page</a>.
      </p>
      `
  },
  {
    
    id: "c0RLJc5m",
    title: "Brussels has what it takes to become the European financial hub for sustainable investments in Africa",
    tags: [],
    date: new Date(Date.UTC(2024, 0, 18)),
    content:
      `
        <p class="flex justify-center my-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/c0RLJc5m/b4844aa3-0fc1-49a3-88a3-36200984d0cb.jpg"} />
        </p>
        <p class="font-semibold">
          For the Brussels-Africa Hub, a renewed partnership between Europe and Africa requires the free flow of capital across the two continents.
        </p>
        <p>
          We believe that Brussels is in a prime position to serve as the European hub where European investors can access, explore and engage with impactful investment opportunities in Africa (from fintech startups promoting financial inclusion in West Africa, to clinics improving healthcare in East Africa, and even to large-scale hybrid, hydro, and solar power plants in Central Africa that are leading the continent towards sustainable energy).
        </p>
        <p>
          Why Brussels? For (at least) three reasons.
        </p>
        <ul>
          <li>
            Firstly, the presence of European institutions make it the best place for developing a new framework that encourages the European private sector and households to invest in impactful projects in Africa. While the EU's Global Gateway €150 billion strategy is a positive step, it falls short of addressing Africa's annual funding gap of approximately €200 billion needed to achieve its Sustainable Development Goals (SDGs).
          </li>
          <li>
            Secondly, Brussels is home to a several international organizations and global entities and provides a unique opportunity for fund managers, entrepreneurs, and companies from both Europe and Africa to access the financial infrastructure necessary to effectively mobilize capital for their African ventures.
          </li>
          <li>
            Finally, Brussels is a unique centre of expertise that gathers an ecosystem of African embassies and institutions, consultants, tax, legal and financial advisors, and diaspora associations that have a deep understanding of the African business and cultural landscape and can thus facilitate capital allocation. This massive and multi-faceted ecosystem further enhances Brussels' reputation as a hub for global cooperation and investment - making it the perfect base for investors looking to tap into the African market.
          </li>
        </ul>
        <p>
          The Presidency of the Council of the European Union offers Belgium a valuable chance to boost the status of Brussels as the European financial hub for sustainable investment in Africa, and to pave the way for the free movement of capital across the Mediterranean in the future.
        </p>
      `
  },
  {
    
    id: "N40itr7K",
    title: "A Transformative Year in Review",
    tags: [],
    date: new Date(Date.UTC(2023, 11, 23)),
    content:
      `
        <p class="flex justify-center my-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/N40itr7K/d4b2e7bd-438d-44e2-a7ff-b00252962885.jpg"} />
        </p>
        <p>
          Throughout this year, the Brussels-Africa Hub has actively fostered collaboration and facilitated dialogue between a variety of stakeholders in Africa and the EU. As we approach the end of 2023, it is time to look back on some of the significant milestones and accomplishments that have shaped the Hub's journey in 2023.
        </p>
        <p class="text-lg font-semibold">
          Building Stronger Partnerships
        </p>
        <p>
          Members of the Hub have dedicated considerable efforts to establishing and strengthening collaborations with governments, international organizations, and businesses. We have partnered with Europe Africa IP to host a roundtable with impact practitioners, discussing impact funds for Europe & Africa and Africa-specific funds, as well as a FinTech initiative focused on Ethiopia and Africa. The Hub also continued its collaboration with INSEAD through the 'Financial Statement Analysis and Valuation' executive program, which took place in Kigali in October. This four-day program caters to mid-level and senior-level executives who seek to use financial statements to assess a company's performance, future prospects and overall value.
        </p>
        <p class="text-lg font-semibold">
          Boosting Trade and Investment
        </p>
        <p>
          We had the opportunity to organize several business events and investment forums throughout the year for entrepreneurs, investors, and policymakers from both regions. Our annual reception in June highlighted Brussels as a major hub for sustainable investments in Africa, and we were particularly glad to see our unique ecosystem of service providers and network of investors coming together with diplomats from both continents to discuss private sector involvement, innovative financing models and policy frameworks to bridge the investment gap.
        </p>
        <p>
          Another significant milestone was achieved in September with the Europe-Africa Impact Investing Summit in Cape Town. The event brought together influential minds from both continents to discuss integrating environmental, social, and economic impact throughout the investment cycle. [<a href="https://www.brightwolves.com/post/europe-africa-impact-investing-summit-2023-a-comprehensive-report" target="_blank" rel="noopener noreferrer">https://www.brightwolves.com/post/europe-africa-impact-investing-summit-2023-a-comprehensive-report</a>]
        </p>
        <p>
          In October, the European Commission officially selected the Hub to be part of the EU Global Gateway Dialogue Platform along with 57 other organizations. This platform allows for sharing expertise and feedback on implementing the Global Gateway in different investment priorities and geographical contexts. The Hub is prepared to contribute its knowledge on impact investments in Africa through the Global Gateway.
        </p>
        <p class="text-lg font-semibold">
          Deeper engagement with key decision-makers
        </p>
        <p>
          In 2023, the Hub has strengthened its connections with influential stakeholders in both the public and private sectors, at the highest level of decision-making. As we move into 2024, the Hub will continue to build upon these accomplishments and harness the potential of capital markets to promote a sustainable Africa-EU partnership. Join us on this fascinating journey and let's position Brussels as a center for impactful and responsible investments in Africa.
        </p>
      `
  },
  {
    
    id: "h2G0ZNdY",
    title: "We are now a member of the EU Global Gateway Dialogue Platform",
    tags: ["Global Gateway"],
    date: new Date(Date.UTC(2023, 9, 25)),
    content:
      `
        <p>
          The Brussels-Africa Hub has been selected by the European Commission as one of the 57 members of the Global Gateway Civil Society and Local Authorities Dialogue Platform.
        </p>
        <p>
          The Global Gateway was launched in 2021 as the European Union's strategy to promote investment in connectivity worldwide. It aims to mobilise €300 billion between 2021-2027, mainly in the digital, climate and energy, transport, health, education and research sectors.
        </p>
        <p>
          The Dialogue Platform will serve as a dedicated space to share expertise and feedback on the rollout of the Global Gateway within different investment priorities and geographic contexts.
        </p>
        <p>
          The Platform's inaugural meeting was held on Tuesday 24 October 2023, chaired by Jutta Urpilainen, European Commissioner for International Partnerships, on the sidelines of the Global Gateway Forum in Brussels.
        </p>
        <p>
          In line with its commitment to promote sustainable and responsible investments between Europe and Africa, the Brussels-Africa Hub is particularly eager to share its expertise on the impact investments needed from the Global Gateway.
        </p>
        <p>
          More information about the Global Gateway Dialogue Platform: <a href="https://international-partnerships.ec.europa.eu/news-and-events/news/global-gateway-commission-launches-civil-society-and-local-authorities-dialogue-platform-2023-10-24_en" target="_blank" rel="noopener noreferrer">https://international-partnerships.ec.europa.eu/news-and-events/news/global-gateway-commission-launches-civil-society-and-local-authorities-dialogue-platform-2023-10-24_en</a>
        </p>
        <div class="flex items-center justify-center flex-wrap mt-6">
          <img src=${process.env.PUBLIC_URL + "/img/news/h2G0ZNdY/352a8ca8-f63a-4bd8-85f7-086c0222c9d2.jpg"} class="m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/news/h2G0ZNdY/fce1cbb5-6ae2-4723-88ae-7f1922d6af22.jpg"} class="m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/news/h2G0ZNdY/e676de4f-c7c1-44e4-9f08-22f421aaf58e.jpg"} class="m-1.5"/>
        </div>
      `
  },
  {
    id: "az9WmdcV",
    title: "Thank you for attending our 2023 Europe-Africa Impact Investing Summit",
    tags: [],
    date: new Date(Date.UTC(2023, 8, 18)),
    content:
      `
        <p>
          We would like to thank everyone for attending our 2023 Europe-Africa Impact Investing Summit. The full downloadable collection of photos can be found on the <a href="https://www.europe-africaimpactsummit.com/gallery" target="_blank" rel="noopener noreferrer">summit website</a>.
        </p>
        <div class="flex items-center justify-center flex-wrap mt-6">
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/1.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/2.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/3.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/4.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/5.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/6.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/7.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/8.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/9.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/4iy0CYcv/photos/10.jpg"} class="max-h-64 m-1.5"/>
        </div>
      `
  },
  {
    id: "OSXeMAHZ",
    title: "How to attract European Institutional Investors in Africa",
    tags: [],
    date: new Date(Date.UTC(2023, 8, 6)),
    content:
      `
        <p>
          Today we are pleased to announce a distinguished panel of experts who will dive into the compelling topic of <b>How to Attract European Institutional Investors in African's Dynamic Markets</b>. This discourse will merge European and African perspectives to uncover strategies, insights, and potential pathways to enticing investments in this dynamic and promising arena.
        </p>
        <p>
          Attracting European institutional investors in Africa requires a deep understanding of economic landscapes, cross-continental collaboration, and sustainable growth prospects. Our esteemed speakers, each armed with unique experiences and insights, will collectively shed light on how African markets can resonate with the discerning European investor community. These speakers include:
        </p>
        <ul>
          <li>Revel Wood - Founding Partner of ONE group solutions. A visionary leader, Revel's experience in aligning financial growth with societal impact adds a unique perspective to the conversation.</li>
          <li>Morné Edas - Founder & Co-Managing Partner of Infra Impact Investment Managers. Morné, a pioneer in infrastructure investments, offers valuable insights into making infrastructure projects attractive to European investors.</li>
          <li>Mpho Ratladi CA (SA) - Finance Director at Norsad Capital. Mpho's extensive experience in financial- and deal making-related roles lends valuable insights into how African exchanges can appeal to European investors.</li>
          <li>Kameel Keshav CA (SA) - Moderator, CEO, CFO, and Independent Board Member. Accomplished executive Kameel Keshav's international business acumen and governance expertise make him the perfect moderator for this engaging discussion.</li>
        </ul>
        <p>
          Join us for a dialogue that promises to be a source of inspiration and knowledge for all seeking to navigate the crossroads of global investment.</p>
        <p class="flex justify-center mt-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/OSXeMAHZ/8c8961f5-ba89-4689-939a-6e4229ce5214.jpeg"} />
        </p>
      `
  },
  {
    id: "4Br1cOHQ",
    title: "Development for impact: A use case from a metropolitan city near Cape Town",
    tags: [],
    date: new Date(Date.UTC(2023, 8, 11)),
    content:
      `
        <p>
          We are excited to announce that INSTINCT has curated a discussion encompassing the importance of <b>Development for Impact</b> at our upcoming <b>Europe-Africa Impact Investing Summit</b>.
        </p>
        <p>
          The most challenging task facing Africa in the 21st century is to transition the role, performance, and resilience of cities in response to increasing pressures of change, and the substantial civic, infrastructure and logistical networks that sustain them. Caroline Sohie and Leszek Dobrovolsky of INSTINCT, together with Vusi Nondo, Development Director at V&A Waterfront and Catherine Stone, Director Spatial Planning at Western Cape Government will, within this context, elaborate on how investors can enable development with impact, as well as how such risks be mitigated, and opportunities be capitalised on.
        </p>
        <p>
          In addition to this illuminating discussion, INSTINCT has organized an exclusive guided tour showcasing development with impact opportunities in Cape Town. This unique experience, including a light lunch, will take place the day after the summit, on Friday 15 September. Ticket sales close for this tour on Tuesday 12 September. Book yours today: <a href="https://www.quicket.co.za/events/226899-europe-africa-impact-investing-summit/#/" target="_blank" rel="noopener noreferrer">https://www.quicket.co.za/events/226899-europe-africa-impact-investing-summit/#/</a>
        </p>
        <p class="flex justify-center mt-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/4Br1cOHQ/677130ae-6ce9-4b72-8696-b20272e593f4.jpeg"} />
        </p>
      `
  },
  {
    id: "AKvvl6Pt",
    title: "Measuring and Reporting Impact: Navigating Sustainable Investment in Emerging Markets",
    tags: [],
    date: new Date(Date.UTC(2023, 8, 8)),
    content:
      `
        <p>
          We are thrilled to invite you to an insightful panel discussion that will serve as a pivotal component of our Europe-Africa Impact Investing summit, titled <b>Measuring and Reporting Impact: Navigating Sustainable Investment in Emerging Markets</b>. The session will revolve around three pivotal themes at the heart of impact measurement and reporting:
        </p>
        <p>
          <b>Measurable Impact through Purposeful Planning & Innovation</b>: Explore the power of strategic planning and innovation.
        </p>
        <p>
          <b>Impact Data Verification</b>: Uncover the importance of robust data verification methods.
        </p>
        <p>
          <b>The Use of Digital Reporting Tech for Measuring and Reporting Impact</b>: Discover the potential of digital technology in impact measurement. Plus, we'll explore prospects for further smart tech integration in the context of the twin transition.
        </p>
        <p>
          The panel will be moderated by Coenraad Bezuidenhout, Principal at BrightWolves South Africa and leading expert in the field of sustainability and impact measurement. Coenraad is joined by three esteemed participants for this engaging conversation: Khomotšo Grace Legodi, Senior Investment Officer at Untapped Global , Kaashifah Beukes, Chief Executive Officer at Freeport Saldanha, and Kay Malela, ESG and Impact Manager at Norsad Capital.
        </p>
        <p>
          This discussion is a unique opportunity to gain insights from leading voices in the field and to network with professionals who share a passion for creating positive change.
        </p>
        <p class="flex justify-center mt-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/AKvvl6Pt/427f5836-00d3-4d67-ba53-f5c9f184c42c.jpeg"} />
        </p>
      `
  },
  {
    id: "Cr18PzYy",
    title: "The Potential vs the Pitfalls of investing in an African Project (a Southern African perspective)",
    tags: [],
    date: new Date(Date.UTC(2023, 8, 5)),
    content:
      `
        <p>
          As we dig into the landscape of investing in African projects, specifically from a Southern African standpoint, we are pleased to introduce a panel of distinguished experts who will guide us through <b>The Potential vs the Pitfalls of investing in an African Project</b> at our upcoming <b>Europe-Africa Impact Investing Summit</b>.
        </p>
        <p>
          Exploring the potential and pitfalls of investing in an African project presents a multifaceted dialogue that requires a deep understanding of regulatory nuances, economic trends, and local realities. Our esteemed speakers will shed light on the <b>strategies, risks, and rewards that come with investing in African ventures</b>. From tax implications and legal frameworks to renewable energy initiatives and regional development, this discussion aims to equip participants with insights crucial for informed decision-making.
        </p>
        <p>
          Join Neethling van Heerden (Tax Director at Renmere Transaction & Tax Advisory), Adrian Dommisse (Director & Founder of Dommisse Attorneys Inc.), Moshie Ratsebe (Director, Investment Promotion at Botswana Investment and Trade Centre), Hendri Herbst (Tax Specialist at Renmere Transaction & Tax Advisory) and Jan Jurie Fourie (Executive Vice President - Sub Sahara Africa at Scatec ASA) to unravel the intricate tapestry of investing in African projects, offering insights that can guide and empower both seasoned investors and those exploring opportunities on the continent.
        </p>
        <p class="flex justify-center mt-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/Cr18PzYy/a9745e81-623d-485a-b104-3a20ff302f55.jpeg"} />
        </p>
      `
  },
  {
    id: "MNat3Ih4",
    title: "Introducing our transport partner for the 2023 Europe-Africa Impact Investing Summit",
    tags: [],
    date: new Date(Date.UTC(2023, 6, 21)),
    content:
      `
        <p>
          We are delighted to include Ethiopian Airlines, Africa's leading aviation group, as our Transport Partner for the 2023 Europe-Africa Impact Investing Summit (Thursday, September 14th - Cape Town, South Africa).
        </p>
        <p class="flex justify-center my-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/MNat3Ih4/e87ee100-5501-475b-a7d3-a93115378e02.webp"} />
        </p>
        <p>
          After registration to the summit, participants who chose to travel with Ethiopian Airlines will benefit from 10-15% discount on their ticket, and a complementary access to the Lounge in Addis Ababa.
        </p>
        <p>
          For more info, please visit <a href="https://www.europe-africaimpactsummit.com" target="_blank" rel="noopener noreferrer">https://www.europe-africaimpactsummit.com</a>
        </p>
      `
  },
  {
    id: "ixd0yMmr",
    title: "First speakers for our 2023 Europe-Africa Impact Investing Summit",
    tags: [],
    date: new Date(Date.UTC(2023, 6, 5)),
    content:
      `
        <p class="flex justify-center my-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/ixd0yMmr/19c28c84-723a-4819-b8bd-53e715f400c1.jpg"} />
        </p>
        <p>
          We are delighted to announce our first speakers for the 2023 Europe-Africa Impact Investing Summit, <a href="https://www.linkedin.com/in/patrice-viaene-2a02a314/" target="_blank" rel="noopener noreferrer">Patrice Viaene</a> and <a href="https://www.linkedin.com/in/mamadou-fall-kane-1b311b160/" target="_blank" rel="noopener noreferrer">Mamadou Fall Kane</a>, both of whom are extremely well positioned to form part of our dynamic panel discussion on the energy transition & clean hydrogen.
        </p>
        <p>
          Patrice is a partner at the Clifford Chance Brussels office and has particular expertise in the renewable energy sector, as a leading member of Clifford Chance's worldwide Clean Hydrogen Task Force. His corporate experience extends across international and domestic M&A, energy, and large infrastructure projects.
        </p>
        <p>
          Mamadou is the energy advisor to the President of the Republic of Senegal, Macky Sall, and is the Deputy Permanent Secretary of the Strategic Orientation Committee for Oil and Gas (COS-PETROGAZ). He has been actively involved in major reforms related to the energy sector and is one of the leading energy experts on the African continent.
        </p>
        <p>
          Organised by <a href="https://www.linkedin.com/company/brightwolves-south-africa/" target="_blank" rel="noopener noreferrer">BrightWolves South Africa</a> and Brussels-Africa Hub, this is your chance to be part of an event that will continue to strengthen the ties between Europe and Africa, and encourage impactful, sustainable and responsible investment in Africa. Topics will range from measuring & reporting on impact, including ESG due diligence, to opportunities and threats of investing in Africa.
        </p>
        <p>
          <a href="https://www.europe-africaimpactsummit.com" target="_blank" rel="noopener noreferrer">Register now</a> and stay tuned for more updates!
        </p>
        <div class="flex items-center justify-center flex-wrap mt-8">
          <img src=${process.env.PUBLIC_URL + "/img/partners/brussels-africa-hub.jpg"} class="h-24 w-auto m-1">
          <img src=${process.env.PUBLIC_URL + "/img/partners/brightwolves.png"} class="h-24 w-auto m-1">
        </div>
      `
  },
  {
    id: "q5u1oSOC",
    title: "Shaping the future of EU-Africa private sector engagement: highlights from our annual reception",
    tags: [],
    date: new Date(Date.UTC(2023, 6, 5)),
    content:
      `
        <p class="text-lg">
          On June 30th, the Brussels Africa Hub held its highly anticipated annual reception, showcasing our mission to establish Brussels as the European hub for sustainable investments in Africa.
        </p>
        <p>
          Our ecosystem of service providers and our network of investors came together with influential diplomats from both continents to explore the next steps for private sector engagement, focusing on innovative financing models and policy frameworks that can bridge the investment gap. 
        </p>
        <p>
          The event, held in sync with our Annual General Assembly, also provided valuable information on new developments and emerging trends that could affect the dynamic EU-Africa business landscape. 
        </p>
        <p>
          In his keynote speech, Mr Bernard Quintin, Deputy Director General Africa at the European External Action Service (EEAS), shared a few insights into the upcoming Belgian presidency of the EU Council. We also took the opportunity to present our executive training project, conducted in partnership with INSEAD, where Mr. Theo Vermaelen, Professor of Finance at INSEAD, joined us remotely all the way from Chicago.
        </p>
        <p>
          Two insightful panels dedicated to Ethiopia and South Africa added another layer of expertise to the event. We were privileged to have both Ambassadors, H.E. Ms Hirut Zemene Kassa and H.E. Ms Tokozile Xasa, open the discussions and shed light on the investment potential in their respective countries. Each panel included perspectives from the private sector, with Mr Gemechis Tujuba, Area manager Benelux at Ethiopian Airlines, and Mr Domien Agten, country manager Brightwolves South Africa, sharing their in-country experience.
        </p>
        <p>
          The event, which concluded with a networking reception, served as platform to emphasize our commitment to help build African bankers' capacity and a strong infrastructure for capital exchange. Moving forward, our main priority continues to be strengthening the financial sector and facilitating the flow of capital as we firmly believe that this will truly unlock Africa's immense economic potential. We look forward to continued partnerships with all stakeholders and building on these promising conversations. Most importantly, we are determined to turn them into tangible action.
        </p>
        <div class="flex items-center justify-center flex-wrap mt-6">
          <img src=${process.env.PUBLIC_URL + "/img/events/TtdXqTBn/photos/bah-reception-2023-4.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/TtdXqTBn/photos/bah-reception-2023-5.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/TtdXqTBn/photos/bah-reception-2023-6.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/TtdXqTBn/photos/bah-reception-2023-7.jpeg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/TtdXqTBn/photos/bah-reception-2023-8.jpeg"} class="max-h-64 m-1.5"/>
        </div>
      `
  },
  {
    id: "Iz2ICkKG",
    title: "Impact Investing in Africa: Get ready to make a difference!",
    tags: [],
    date: new Date(Date.UTC(2023, 5, 6)),
    content:
      `
        <p class="flex justify-center my-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/Iz2ICkKG/7c83f05f-2d0b-466a-b9f0-9f7c0c586715.png"} class="max-w-3/4" />
        </p>
        <p>
          Join us at the Europe-Africa Impact Investing Summit in Cape Town taking place on 14 September 2023, for thought-provoking discussions on incorporating impact throughout the investment lifecycle. Engage with investors, visionary entrepreneurs and industry experts to explore innovative solutions and collaborate on creating a better future for all.
        </p>
        <p>
          Organised by BrightWolves South Africa and the Brussels-Africa Hub, this is your chance to be part of an event that will continue to strengthen the ties between Europe and Africa, and encourage impactful, sustainable and responsible investment in Africa. Topics will range from measuring & reporting on the impact, including ESG due diligence, to opportunities and threats of investing in Africa.
        </p>
        <p>
          Register now at <a href="https://www.europe-africaimpactsummit.com" target="_blank" rel="noopener noreferrer">https://www.europe-africaimpactsummit.com</a>
        </p>
        <div class="flex items-center justify-center flex-wrap mt-8">
          <img src=${process.env.PUBLIC_URL + "/img/partners/brussels-africa-hub.jpg"} class="h-24 w-auto m-1">
          <img src=${process.env.PUBLIC_URL + "/img/partners/brightwolves.png"} class="h-24 w-auto m-1">
        </div>
      `
  },
  {
    id: "mnUu3CYa",
    title: "Brussels-Africa Hub partners with BrightWolves",
    tags: [],
    date: new Date(Date.UTC(2023, 1, 16)),
    content:
      `
        <p class="flex justify-center">
          <img src=${process.env.PUBLIC_URL + "/img/partners/brightwolves.webp"}>
        </p>
        <p>
          The Brussels-Africa Hub and <a href="https://www.brightwolves.com" target="_blank" rel="noopener noreferrer">BrightWolves</a> are proud to announce a strategic partnership. This collaboration brings together the resources and expertise of two leading organizations committed to making a positive impact in African businesses.
        </p>
        <p>
          The Brussels-Africa Hub is a diaspora-led international non-profit organization with the mission to unlock Africa's true potential and position Brussels as the European hub for impactful, sustainable and responsible (ISR) investments towards Africa. Founded in 2017, the Brussels-Africa Hub promotes and facilitates ISR investments in Africa through several initiatives around four pillars: Investment & Advisory, Research & Policymaking, Capacity-building, and Networking & Stakeholder engagement.
        </p>
        <p>
          BrightWolves is a consulting firm that delivers sustainable business solutions, from strategy to implementation, along three core areas of expertise:  Business, Digital & Data and Sustainability. With headquarters in Brussels and a newly launched South African entity, BrightWolves is well-positioned to bring its global expertise to the local market and deliver made-to-measure solutions.
        </p>
        <p>
          BrightWolves will also be launching a Brussels-Africa Hub chapter in South Africa, which will help to bring the two organizations' expertise and resources closer together.
        </p>
        <p>
          <i>“I'm really excited to partner with BrightWolves to drive sustainable and responsible investment in the African continent and strengthen EU-Africa relationships.”</i> said Mouctar Bah, president of the Brussels-Africa Hub. <i>“I have full confidence in BrightWolves taking charge of the South African chapter of the Brussels-Africa Hub, and look forward to the first fruitions from our collaboration.”</i>
        </p>
        <p>
          Miguel Van Damme, Managing Partner at BrightWolves, added <i>“We are very happy to partner with the Brussels-Africa Hub, and are more than ever committed to the sustainable development of the African continent. Since our first meeting with the Brussels-Africa Hub, we felt a great connection and common drive to strengthen the EU-Africa relationships.”</i>
        </p>
        <p>
          The partnership between Brussels-Africa Hub and BrightWolves will first culminate with a co-hosted event in Cape Town in the second half of 2023. The event will explore EU-Africa impact investing and bring together stakeholders from across the impact investment community.
        </p>
        <p>
        <a href="https://www.brightwolves.com/post/brightwolves-south-africa-joins-forces-with-brussels-africa-hub" target="_blank" rel="noopener noreferrer">https://www.brightwolves.com/post/brightwolves-south-africa-joins-forces-with-brussels-africa-hub</a>
        </p>
      `
  },
  {
    id: "Dy2LysGs",
    title: "Business with Ethiopia: Insights on the Ethiopian business ecosystem - In picture",
    tags: [],
    date: new Date(Date.UTC(2022, 9, 18)),
    content:
      `
        <p>
          We would like to thank everyone for attending the Business with Ethiopia event. The photos and a video can be found on the <a href="/events/4sMzn169/business-with-ethiopia-insights-on-the-ethiopian-business-ecosystem">event page</a>.
        </p>
        <p class="flex justify-center mt-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Z2TDg9mlLj0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </p>
      `
  },
  {
    id: "npgzcUzC",
    title: "Join us for Business with Ethiopia: Insights on the Ethiopian business ecosystem",
    tags: [],
    date: new Date(Date.UTC(2022, 8, 29)),
    content:
      `
        <p>
          The Brussels-Africa Hub is cooperating with the BelgoEthiopian Association and the Ethiopian Embassy in Brussels to organize an event on October 6 focused on Insights on Doing Business with Ethiopia.
        </p>
        <p>
          More info and a link to register can be found <a href="/events/4sMzn169/business-with-ethiopia-insights-on-the-ethiopian-business-ecosystem">here</a>.
        </p>
        <p class="flex justify-center mt-8">
          <img src=${process.env.PUBLIC_URL + "/img/news/npgzcUzC/4dafcc4c-472c-47a7-b206-96831b5fb81a.jpg"} class="max-w-3/4" />
        </p>
      `
  },
  {
    id: "golctLp2",
    title: "Recap of the INSEAD 'Valuation and Risk Management in Banking' executive programme",
    tags: [],
    date: new Date(Date.UTC(2022, 4, 16)),
    content:
      `
        <p>
          With 28 leading bankers based in Kigali-Rwanda participating in the first INSEAD 'Valuation and Risk Management in Banking' executive programme we can state that it was a success.
        </p>
        <p>
          The programme is the result of a fruitful partnership between INSEAD, Kigali International Financial Centre, Rwanda Development Board (RDB), Rwanda Bankers' Association and the Brussels-Africa Hub, and marks an important first step towards a permanent INSEAD presence in Africa
        </p>
        <p class="flex justify-center mt-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Sxu0KhOeVS0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </p>
      `
  },
  {
    id: "H5puuWoY",
    title: "INSEAD 'Valuation and Risk Management in Banking' executive programme",
    tags: [],
    date: new Date(Date.UTC(2022, 2, 31)),
    content:
      `
        <p>
          We are proud to announce that the Brussels-Africa Hub is partnering with the Rwanda Bankers' Association, Rwanda Finance Limited and Rwanda Development Board to organize an <b>INSEAD Valuation and Risk Management in Banking</b> executive programme.
        </p>
        <p>
          In today's constantly shifting global economy and turbulent business environment, organisations face making financial decisions that are more complex and challenging than ever before. Differences in regulation, taxation, political risk and governance quality all have an impact on the way such decisions should be made.
        </p>
        <p>
          Company valuations, mergers and acquisitions and IPOs are no exception. With an ever shifting global corporate environment, how do you ensure that you have the valuation skills needed to best advise your clients on how to create shareholder value?
        </p>
        <p>
          And as the banking world is shifting from an overriding concern for growth to a preoccupation with risk control and long-term sustainable value creation, how do you help your clients measure risk?
        </p>
        <p>
          This programme, held for a first time at the Kigali Convention Center (KCC) from 25 to 29 April 2022, will shine a light on the latest methodologies to value companies, estimate the cost of capital in emerging markets and an understanding of the sources of value-creation in banking.
        </p>
        <div class="flex items-center justify-center flex-wrap mt-8">
          <img src=${process.env.PUBLIC_URL + "/img/partners/insead.png"} class="h-24 w-auto m-1">
          <img src=${process.env.PUBLIC_URL + "/img/partners/kigali-international-financial-centre.jpg"} class="h-24 w-auto m-1">
        </div>
        <div class="flex items-center justify-center flex-wrap mt-4">
          <img src=${process.env.PUBLIC_URL + "/img/partners/rwanda-bankers-association.jpg"} class="h-24 w-auto m-1">
          <img src=${process.env.PUBLIC_URL + "/img/partners/rwanda-finance-limited.jpg"} class="h-24 w-auto m-1">
          <img src=${process.env.PUBLIC_URL + "/img/partners/rwanda-development-board.jpg"} class="h-24 w-auto m-1">
        </div>
      `
  },
  {
    id: "clyOhwoz",
    title: "Our mission to Kigali - October 2021",
    tags: [],
    date: new Date(Date.UTC(2021, 10, 9)),
    content:
      `
        <p>
          Last month, five Board members of the Brussels-Africa Hub headed to Kigali (Rwanda) to advance important initiatives to promote sustainable investment in Africa. The team met with the top management of critical institutions, including the National Bank of Rwanda (BNR), the Kigali International Financial Centre and the Rwanda Development Board (RDB), among other prominent public and private organisations. The quality of the discussions, the clear vision for the country and the continent, and the drive of the entrepreneurs we met leave us hopeful for what comes next!
        </p>
        <p class="flex justify-center mt-8">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/zGpqN8LLXJg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </p>
      `
  },
  {
    id: "IWbuRNbV",
    title: "Call for projects SusTech4Africa - Diaspora edition 2021",
    tags: [],
    date: new Date(Date.UTC(2021, 0, 22)),
    content:
      `
        <p class="flex justify-center">
          <img src=${process.env.PUBLIC_URL + "/img/news/IWbuRNbV/f9d5ed8f-3e0a-413f-967d-90e078d7d31e.jpeg"} class="max-w-3/4 mt-2 mb-3" />
        </p>
        <p>
          Our partner Entrepreneurs for Entrepreneurs (OVO), together with Thomas More University, The African Diaspora Projects Initiative, VITO and the Flemish government, invites entrepreneurs who are members of the African Diaspora in Europe with a good economic project in Africa to participate in the competition "Sustainable Technology for Africa" (SusTech4Africa).
        </p>
        <p>
          Do you have (an idea for) a social enterprise in Africa, or (for) a sustainable product? Would you like to further elaborate and boost this? SusTech4Africa helps companies and (aspiring) entrepreneurs with an African background to strengthen the business model and develop a full-fledged business plan. What's more, as a winner you can also get a starting capital in the form of a social loan.        
        </p>
        <p>
          More info and a form to sign up can be found <a href="https://ondernemersvoorondernemers.be/en/sustech4africa/sustech4africa-diaspora-2021-call-for-projects" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      `
  },
  {
    id: "QowIaone",
    title: "Tech Mission to Rwanda/Uganda",
    tags: [],
    date: new Date(Date.UTC(2020, 6, 3)),
    content:
      `
        <p>
          Originally planned for March 24-28, 2020, the Tech Mission to Rwanda/Uganda will now take place from <b>November 16-21, 2020</b>:
        </p>
        <p>
          As you may have heard, Close the Gap, Startups.be and Agoria joined forces to organize the third tech mission in the framework of the <a href="https://www.close-the-gap.org/what-we-do/d4d-be-platform" target="_blank" rel="noopener noreferrer">Digital for Development</a> program.  From <b>November 16 to 21, 2020</b>, they will discover <b>Rwanda and Uganda's booming tech startup ecosystem</b>. Together with a delegation of <b>startups, scaleups, entrepreneurs, NGOs, academics and Belgian press members</b>, they will find out how Rwanda and Uganda <b>embrace and amplify technology for the better of their citizens and ignite partnerships</b>. 
        </p>
        <p>
          We are very happy to unveil some of the highlights of the <a href="https://www.close-the-gap.org/rwanda-uganda" target="_blank" rel="noopener noreferrer">exciting program</a> here, which are:
          <ul>
            <li><b>Zipline</b>'s revolutionary drone-approach to health care, where they provide blood and medicine to remote areas by drones.</li>
            <li>A <b>co-creation session with CcHub</b>, together with local Rwandan startups. CcHub's CEO, Bosun Tijani, will personally welcome our delegation. CcHub is one of Africa's largest networks of Hubs supporting innovation and entrepreneurship.</li>
            <li>A 'reverse pitching'-session at <b>Red Cross Kigali</b> where participating Belgian NGOs will pitch their digital needs and showcase their D4D initiatives.</li>
            <li>A visit to the newly established <b>Carnegie Mellon University</b> and its industry lab, located in the <b>Kigali Innovation City</b>.</li>
            <li>A session at the <b>Volkswagen</b> assembly plant to discover their <b>e-mobility program</b> and approach.</li>
            <li>A visit to the <b>Mara Phones plant</b>, the very first smartphone 100% assembled and produced in Africa.</li>
            <li>A visit to the <b>Kampala Innovation Village</b>, combined with a <b>B2B session</b> co-organized with <b>FIT, AWEX and hub.brussels</b></li>
            <li>A closing session at <b>Design Hub in Kampala</b>, organised <b>by Enabel</b> about "Private Sector Development"</li>
            <li>Receptions hosted by the respective <b>Belgian Embassies</b></li>
          </ul>
        </p>
        <p>
          To register for the mission, sign up <a href="https://docs.google.com/forms/d/e/1FAIpQLScvxVce51RU3lNvGQZ8Vrg4mWa8ww8Upd8BPLpT-avv48aH2g/viewform" target="_blank" rel="noopener noreferrer">here</a>. Don't wait, we only have 40 seats! More info can be found <a href="https://www.close-the-gap.org/rwanda-uganda" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      `
  },
  {
    id: "Eo0-2Gy5",
    title: "African Diaspora Survey",
    tags: [],
    date: new Date(Date.UTC(2020, 5, 25)),
    content:
      `
        <p class="text-xs">
          <i>French and Dutch below</i>
        </p>
        <p>
          The Diaspora is an asset to Africa. Mobilizing the African diaspora and its resources is crucial to transform Africa and help the continent materialize its master plan.
        </p>
        <p>
          For this reason, the <b>Brussels-Africa Hub</b> and <b>LITA.be</b>, the European impact investing and crowdfunding player, are launching this African Diaspora Survey to understand the diaspora investment habits, in general, and its interest in impact investing in Africa, in particular.
        </p>
        <p>
          Complete the survey for a chance to win an iPad Air:<br/>
          <a href="https://forms.gle/bfiDyCenJXVV6bN97" target="_blank" rel="noopener noreferrer">https://forms.gle/bfiDyCenJXVV6bN97</a>
        </p>
        <hr class="w-1/20 mt-2 mb-2.5" align="left"/>
        <p>
          La Diaspora est un atout pour l'Afrique. Mobiliser les ressources de cette diaspora est crucial pour transformer le Continent et l'aider à mettre en oeuvre ses plans stratégiques.
        </p>
        <p>
          C'est pourquoi le <b>Brussels-Africa Hub</b> et <b>LITA.be</b>, l'acteur européen d'impact investing et crowdfunding, lancent cette grande enquête afin de comprendre les habitudes d'investissement de la diaspora africaine, en général, ainsi que son intérêt pour l'investissement d'impact en Afrique, en particulier.
        </p>
        <p>
          Participez à cette enquête pour avoir une chance de gagner un iPad Air:<br/>
          <a href="https://forms.gle/ZB95ns4xp6KjZP6a6" target="_blank" rel="noopener noreferrer">https://forms.gle/ZB95ns4xp6KjZP6a6</a>
        </p>
        <hr class="w-1/20 mt-2 mb-2.5" align="left"/>
        <p>
          De diaspora is een kracht voor Afrika. Het mobiliseren van de Afrikaanse diaspora en haar middelen is cruciaal om Afrika te transformeren en het continent te helpen zijn masterplan te verwezenlijken.
        </p>
        <p>
          Daarom lanceren de <b>Brussels-Africa Hub</b> en <b>LITA.be</b>, de Europese impact investing en crowdfunding actor, deze African Diaspora Survey om de investeringsgewoonten van de diaspora in het algemeen en hun interesse in impact investing in Afrika, in het bijzonder, te begrijpen.
        </p>
        <p>
          Vul de enquête in om kans te maken op een iPad Air:<br/>
          <a href="https://forms.gle/QezdcwRFMAQkvAYt7" target="_blank" rel="noopener noreferrer">https://forms.gle/QezdcwRFMAQkvAYt7</a>
        </p>
      `
  },
  {
    id: "12JzVeFY",
    title: "EU-Canada Business Summit 2020",
    tags: [],
    date: new Date(Date.UTC(2020, 5, 16)),
    content:
      `
        <p class="flex justify-center">
          <img src=${process.env.PUBLIC_URL + "/img/news/12JzVeFY/b495efd6-0ac7-40b0-bac0-986ccb132774.jpg"} class="max-w-3/4 mt-2 mb-3" />
        </p>
        <p>
          The <b>EU-Canada Business Summit</b> is a <b>one day event</b> held once a year in <b>Brussels</b>, capital of the European Union, that brings together hundreds of political and business decision-makers <b>from Canada, the EU and the world</b>.
        </p>
        <p>
          The latest political, financial & economic global trends are being discussed and analyzed by relevant high-level speakers coming from a vast array of sectors. This year's topics are <b>Energy & Environment, Security & Transport, International Trade & Mobility, Manufacturers and Artificial Intelligence</b>.
        </p>
        <p>
          The <b>EU-Canada Business Summit</b> is a great way to <b>built business relationships</b> through VIP cocktails, private meetings, interactive debates and several exclusive networking activities.
        </p>
        <p>
          More info can be found <a href="https://www.canadaeurope.eu/event/eu-canada-business-summit-2019/" target="_blank" rel="noopener noreferrer">here</a> and registering can be done <a href="https://www.eventbrite.com/e/eu-canada-business-summit-2020-tickets-92894019513" target="_blank" rel="noopener noreferrer">here</a>.<br/>
          A <b>65% Early bird discount</b> is available with this code: <b>EUCanada2020</b>
        </p>
      `
  },
  {
    id: "GBLEhiWN",
    title: "The Brussels-Africa Hub joins the African Crowdfunding Association",
    tags: [],
    date: new Date(Date.UTC(2020, 3, 20)),
    content:
      `
        <p>
          As of today the Brussels-Africa Hub is a member of the African Crowdfunding Association.
        </p>
        <p>
          Founded in 2015 by several crowdfunding platforms, ACfA has a pan-African mission to develop and foster crowdfunding as a formalised development finance tool to broaden SME access to finance. ACfA works to harmonise crowdfunding regulations across Africa through the adoption of the ACfA Label Framework, an interim regulatory framework for crowdfunding. The association also aims to provide industry data and research, facilitate policy dialogue and to drive adoption of crowdfunding across the ecosystem, from ordinary retail investors to development finance institutions.
        </p>
        <p class="flex justify-center mt-5">
          <a href="http://files.brusselsafricahub.eu/ACfA_Certificate.pdf" target="_blank" rel="noopener noreferrer"><img src=${process.env.PUBLIC_URL + "/img/news/GBLEhiWN/028bec84-b242-4698-9a9e-2fc7f80a2742.jpg"} class="h-[500px]" /></a>
        </p>
      `
  },
  {
    id: "szmpdBzI",
    title: "Tech Mission to Rwanda/Uganda",
    tags: [],
    date: new Date(Date.UTC(2020, 0, 8)),
    content:
      `
        <p>
          As you may have heard, Close the Gap, Startups.be and Agoria joined forces to organize the third tech mission in the framework of the <a href="https://www.close-the-gap.org/what-we-do/d4d-be-platform" target="_blank" rel="noopener noreferrer">Digital for Development</a> program.  From <b>March 24 to 28, 2020</b>, they will discover <b>Rwanda and Uganda's booming tech startup ecosystem</b>. Together with a delegation of <b>startups, scaleups, entrepreneurs, NGOs, academics and Belgian press members</b>, they will find out how Rwanda and Uganda <b>embrace and amplify technology for the better of their citizens and ignite partnerships</b>. 
        </p>
        <p>
          We are very happy to unveil some of the highlights of the <a href="https://www.close-the-gap.org/rwanda-uganda" target="_blank" rel="noopener noreferrer">exciting program</a> here, which are:
          <ul>
            <li><b>Zipline</b>'s revolutionary drone-approach to health care, where they provide blood and medicine to remote areas by drones.</li>
            <li>A <b>co-creation session with CcHub</b>, together with local Rwandan startups. CcHub's CEO, Bosun Tijani, will personally welcome our delegation. CcHub is one of Africa's largest networks of Hubs supporting innovation and entrepreneurship.</li>
            <li>A 'reverse pitching'-session at <b>Red Cross Kigali</b> where participating Belgian NGOs will pitch their digital needs and showcase their D4D initiatives.</li>
            <li>A visit to the newly established <b>Carnegie Mellon University</b> and its industry lab, located in the <b>Kigali Innovation City</b>.</li>
            <li>A session at the <b>Volkswagen</b> assembly plant to discover their <b>e-mobility program</b> and approach.</li>
            <li>A visit to the <b>Mara Phones plant</b>, the very first smartphone 100% assembled and produced in Africa.</li>
            <li>A visit to the <b>Kampala Innovation Village</b>, combined with a <b>B2B session</b> co-organized with <b>FIT, AWEX and hub.brussels</b></li>
            <li>A closing session at <b>Design Hub in Kampala</b>, organised <b>by Enabel</b> about "Private Sector Development"</li>
            <li>Receptions hosted by the respective <b>Belgian Embassies</b></li>
          </ul>
        </p>
        <p>
          If interested, we invite you to fill out the registration form <a href="https://docs.google.com/forms/d/e/1FAIpQLScvxVce51RU3lNvGQZ8Vrg4mWa8ww8Upd8BPLpT-avv48aH2g/viewform" target="_blank" rel="noopener noreferrer">here</a> as soon as possible, so that <b>flights can be booked rapidly</b> to ensure affordable prices and availabilities. All details are available <a href="https://www.close-the-gap.org/rwanda-uganda" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      `
  },
  {
    id: "pDgmEFEz",
    title: "Happy Holidays from the Brussels-Africa Hub Team!",
    tags: [],
    date: new Date(Date.UTC(2019, 11, 24)),
    content:
      `
        <p>
          The entire Brussels-Africa Hub team whishes you a Merry Christmas and a Happy New Year! All the best for 2020.
        </p>
      `
  },
  {
    id: "ro0DrEel",
    title: "The Brussels-Africa Hub signs the Belgian SDG Charter for Development",
    tags: [],
    date: new Date(Date.UTC(2019, 11, 11)),
    content:
      `
        <p>
          The Brussels-Africa Hub was born from the belief that the development of the private sector in Africa plays a critical role in the development of Africa as a whole and in realizing the Sustainable Development Goals (SDGs). With everything we do we have the SDGs in our mind, and by signing the Belgian SDG Charter for International Development we want to highlight their importance and our commitment to them. 
        </p>
        <p class="flex justify-center mt-5">
          <img src=${process.env.PUBLIC_URL + "/img/news/ro0DrEel/cef105ab-bd53-4203-b0b8-e54aa8505473.jpeg"} class="h-36" />
        </p>
        <p class="mt-5">
          More info about the Belgian SDG Charter for Development can be found <a href="https://theshift.be/en/projects/belgian-sdg-charter-for-development" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      `
  },
  {
    id: "PKyADZ2R",
    title: "Investing in Ethiopia: A private equity perspective",
    tags: [],
    date: new Date(Date.UTC(2019, 11, 8)),
    content:
      `
        <p>
          Thank you for attending the event on Private equity investments in Ethiopia.
        </p>
        <p>
          We sincerely believe that the country presents a huge opportunity in the context of the topic discussed during the workshop and the specific sectors that Ethiopia is pursuing as national priorities. The Ethiopian Embassy together with the Brussels-Africa Hub, is fully committed to provide you with further guidance and needed support as you take early steps of exploring those opportunities.
        </p>
        <div class="flex items-center justify-center flex-wrap mt-6">
          <img src=${process.env.PUBLIC_URL + "/img/events/3T2vFq1q/photos/g.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/3T2vFq1q/photos/a.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/3T2vFq1q/photos/e.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/3T2vFq1q/photos/f.jpg"} class="max-h-64 m-1.5"/>
          <img src=${process.env.PUBLIC_URL + "/img/events/3T2vFq1q/photos/k.jpg"} class="max-h-64 m-1.5"/>
        </div>
      `
  },
  {
    id: "gLdCJ67B",
    title: "Join us for Investing in Ethiopia: A private equity perspective",
    tags: [],
    date: new Date(Date.UTC(2019, 10, 17)),
    content:
      `
        <p>
          The Brussels-Africa Hub is cooperating with the Ethiopian Embassy in Brussels to organize a panel event on November 28 focused on Ethiopia and it's opportunities for PE and impact investors.
        </p>
        <p>
          More info and a link to register can be found <a href="/events/3T2vFq1q/investing-in-ethiopia-a-private-equity-perspective" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      `
  },
  {
    id: "ge8PAvwF",
    title: "Join us for the OVO Congres about Sustainable Entrepreneurship in Africa!",
    tags: [],
    date: new Date(Date.UTC(2019, 10, 16)),
    content:
      `
        <p>
          On Friday, 29 November, OVO (Entrepreneurs for Entrepreneurs) is staging its annual congress for the sixth time. It is being hosted by Herman Daems, Chairman of the Board of BNP Paribas Fortis, and Luc Bonte, Chairman of the Board of Entrepreneurs for Entrepreneurs (OVO), who will welcome you to an enthralling afternoon on the subject “Sustainable business in Africa”.
        </p>
        <p>
          As a partner we can offer a limited amount of discounted tickets (€50 instead of €125) on a first come, first served basis. If you would like to register, shoot us an email at <a href="mailto:info@brusselsafricahub.eu?subject=Join us for the OVO Congres about Sustainable Entrepreneurship in Africa">info@brusselsafricahub.eu</a>.
        </p>
        <p>
          More info about the event can be found <a href="https://ondernemersvoorondernemers.be/en/agenda/eCongres" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      `
  },
  {
    id: "7Kw3MZ4P",
    title: "Happy Holidays from the Brussels-Africa Hub Team!",
    tags: [],
    date: new Date(Date.UTC(2018, 11, 24)),
    content:
      `
        <p>
          The entire Brussels-Africa Hub team whishes you a Merry Christmas and a Happy New Year! All the best for 2019.
        </p>
      `
  },
  {
    id: "RNE5dt8h",
    title: "Start of our monthly newsletter",
    tags: [],
    date: new Date(Date.UTC(2018, 5, 6)),
    content:
      `
        <p>
          Starting today we will be sending out a monthly newsletter containing a hand picked selection of our favourite articles about the banking & finance, fintech, capital market, energy, infrastructure, etc. industries in Africa.
        </p>
        <p>
          If we have your email address, our first edition can be found in your mailbox. If not, you can sign up to our mailing list <a href="http://eepurl.com/c-4RcT" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      `
  },
  {
    id: "2MNciTub",
    title: "Interview with our COO Gilles Bazambanza",
    tags: [],
    date: new Date(Date.UTC(2017, 10, 22)),
    content:
      `
        <p class="flex justify-center">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/zUVisnwsJKQ?rel=0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
        </p>
      `
  }
];

export default posts;