import React from 'react';
import { Link } from "react-router-dom";

import H2 from "../components/H2"
import Post from '../components/Post';

import posts from '../data/posts';
import partners from '../data/partners';

export default function Home() {
  const numberOfPostsToShow = 5;

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-2/3">
        <H2 hrClassName="mb-0" title="Latest news" subtitle="Latest news about the Hub and its members" />
        {posts.slice(0,numberOfPostsToShow).map((post, index) => (
          <div key={post.id}>
            <Post post={post} />
            {index < numberOfPostsToShow - 1 && <hr className="w-1/6 border-bahyellow border-b border-t-0" />}
          </div>
        ))}
      </div>
      <div className="w-full flex flex-col lg:w-1/3 lg:pl-12">
        <H2 title="Become a member" />
        <span className="mt-2 text-center">Our ecosystem of impact makers is growing</span>
        <Link to="/join-us" className="inline-block self-center mt-5 rounded-full bg-bahyellow px-6 py-3">JOIN THE HUB</Link>
        <H2 title="Partners" />
        <div className="flex flex-wrap items-center">
          {partners.map((partner) => (
            <a key={partner.link} className="w-1/3 lg:w-1/2 p-4" href={partner.link} target="_blank" rel="noopener noreferrer"><img src={partner.logo} alt={partner.name} /></a>
          ))}
        </div>
      </div>
    </div>
  );
}